




























































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Subject, Subscription } from "rxjs";
import { tap, debounceTime, map } from "rxjs/operators";
import FormComponentSelect from "@/app/dynamic-components/forms/form-components/components/depricated/form-component-select.vue";
import { documentServiceV2 } from "@/app/services/document.service";
import { userServiceV2 } from "@/app/services/user.service";
import { organisationService } from "@/app/services/organisation.service";
import { ExternalContext } from "@/app/contexts/externalContext";
import { roleService } from "@/app/services/role.service";
import { DataTableHeader } from "vuetify";
import {ActivityDocumentSharingModel} from "@/app/models/activity/activity-definition.model";
import IamRole from "@/contracts/iam/IamRole";
import {ruleEngine} from "@/app/services/rule.engine";
import {configService} from "@/services/config.service";
import store from "@/store";

@Component({
  components: { FormComponentSelect },
})
export default class ShareDocumentDialogComponent extends Vue{
  @Prop({ default: false })
  show!: boolean;

  shareOptions: {[key: string]: {id: string, label: string}[]} = {}

  @Prop({ default: {} })
  sharing!: ActivityDocumentSharingModel[];

  @Prop({ default: {} })
  sharingScopes!: {
    fullAccess: string[]
  };

  @Watch("sharing", { immediate: true, deep: true })
  async setSharing(sharing: ActivityDocumentSharingModel[], oldValue: ActivityDocumentSharingModel) {
    this.contactTypes = [];
    this.shareOptions = {};

    if(!sharing || sharing.length <= 0){
      return;
    }

    const userRoles: IamRole[] = this.$store.state.user.roles;
    if(!userRoles || userRoles.length <= 0){
      return;
    }

    this.roleTranslationList = {};
    sharing.forEach(v => {
      if(v.selectableRoles && Object.keys(v.selectableRoles).length > 0){
        Object.entries(v.selectableRoles).forEach(value => {
          this.roleTranslationList[value[0]] = value[1];
        })
      }
    })

    const shareOptions = {};
    for (const shr of sharing) {
      if(!shr.canShareWith) continue;
      const validShareOption = await ruleEngine.resolveRuleAsync(this.externalContext, shr.rule, this);
      if(!validShareOption) continue;

      shr.canShareWith.forEach(canShareWith => {
        if(!shareOptions[canShareWith]) shareOptions[canShareWith] = {};
        if(shr.selectableRoles){
          Object.entries(shr.selectableRoles).forEach(value => shareOptions[canShareWith][value[0]] = value[1])
        }
      })
    }

    Object.entries(shareOptions).forEach(sh => {
      if(sh[1] && Object.keys(sh[1] as any).length > 0){
        const a = Object.entries(sh[1] as any).map(value => {
          return {id: value[0], label: this.$t(value[1] as any).toString()}
        })
        this.shareOptions[sh[0]] = a as any;
      }else{
        this.shareOptions[sh[0]] = [];
      }

    })
    this.contactTypes = Object.keys(shareOptions).map(value => {
      return {
        id: this.contactTypeValues[value].id,
        label: this.$t(this.contactTypeValues[value].label).toString()
      }
    });
    this.selectedContactType = null;
    /*if(Object.keys(shareOptions).length > 0){
      this.selectedContactType = Object.keys(shareOptions)[0] as string;
    }*/
  }

  @Prop({
    default: () => {
      return null;
    },
  })
  documentItem!: any;
  @Watch("documentItem", { immediate: true, deep: false })
  async setRelatedDocumentProperties(newValue: any, oldValue: any) {
    this.reset();
    await this.fetchDocumentShares();
    this.$forceUpdate();
  }

  @Prop({
    default: () => {
      return new ExternalContext();
    },
  })
  externalContext!: ExternalContext;

  @Prop({ default: "1450" })
  dialogWidth!: string;

  public isLoading = false;
  public isOwner = false;

  public contactTypeValues = {
    'ALL_USERS': {id: 'ALL_USERS', label: 'sharing.contacttype.allusers', sharedLabel: 'sharing.contacttypeshared.allusers'},
    'USERS_WITH_ROLE': {id: 'USERS_WITH_ROLE', label: 'sharing.contacttype.userswithrole', sharedLabel: 'sharing.contacttypeshared.userswithrole'},
    'ORGANISATIONS_WITH_ROLE': {id: 'ORGANISATIONS_WITH_ROLE', label: 'sharing.contacttype.orgswithrole', sharedLabel: 'sharing.contacttypeshared.orgswithrole'},
    'USER_IN_CURRENT_ORGANISATION': {id: 'USER_IN_CURRENT_ORGANISATION', label: 'sharing.contacttype.userinownorg', sharedLabel: 'sharing.contacttypeshared.userinownorg'},
    'BCCA': {id: 'BCCA', label: 'sharing.contacttype.bcca', sharedLabel: 'sharing.contacttypeshared.bcca'},
    "USER": {id: 'USER', label: 'sharing.contacttype.user', sharedLabel: 'sharing.contacttypeshared.user'},
    "ORGANISATION": {id: 'ORGANISATION', label: 'sharing.contacttype.org', sharedLabel: 'sharing.contacttypeshared.org'},
    "OWNER": {id: 'OWNER', label: 'sharing.contacttype.owner', sharedLabel: 'sharing.contacttypeshared.owner'}
  }
  public roleTranslationList: {[key: string]: string} = {};
  public contactTypes: {id: string, label: string}[] = [];
  public selectedContactType: string | null = null;
  public selectedRoles: any[] = [];
  public eligibleParties: any[] = [];
  public selectedParties: any[] = [];

  public desiredParties: any[] = [];

  public onSelectRoleSubscription : Subscription | undefined;
  public onSelectRoleSubject : Subject<any> = new Subject();
  public loadingEligibleParties = false;

  mounted(): void {
    this.reset();
    this.onSelectRoleSubscription = this.onSelectRoleSubject.pipe(
        tap(ignore => {this.loadingEligibleParties = true}),
        debounceTime(500)
    ).subscribe(async ignore => {
      try{
        await this.updateEligibleParties();
      }catch (e) {
        console.error(e)
      }finally {
        this.loadingEligibleParties = false;
      }
    })
  }

  beforeDestroy(): void {
    if (this.onSelectRoleSubscription) {
      this.onSelectRoleSubscription.unsubscribe();
      this.onSelectRoleSubscription = undefined;
    }
  }

  public onSelectContactType(){
    this.selectedRoles = [];
    this.eligibleParties = [];
    this.selectedParties = [];
    this.selectedParties = [];
  }

  public onSelectRoles(){
    this.onSelectRoleSubject.next();
  }

  public isValidInput(): boolean{
    if(this.isLoading) return false;
    if(this.loadingEligibleParties) return false;
    if(this.selectedContactType === 'ALL_USERS') return true;
    if(this.selectedContactType === 'BCCA') return true;
    if(this.selectedContactType === 'ORGANISATIONS_WITH_ROLE') return this.selectedRoles && this.selectedRoles.length > 0;
    if(this.selectedContactType === 'USERS_WITH_ROLE') return this.selectedRoles && this.selectedRoles.length > 0;
    if(this.selectedContactType === 'USER_IN_CURRENT_ORGANISATION') return this.selectedRoles && this.selectedRoles.length > 0 && this.selectedParties && this.selectedParties.length > 0;
    if(this.selectedContactType === 'USER') return this.selectedRoles && this.selectedRoles.length > 0 && this.selectedParties && this.selectedParties.length > 0;
    if(this.selectedContactType === 'ORGANISATION') return this.selectedRoles && this.selectedRoles.length > 0 && this.selectedParties && this.selectedParties.length > 0;
    return false;
  }

  private get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t("share.headers.contactType").toString(),
        value: "contactTypeTranslation",
        sortable: false
      },
      {
        text: this.$t("share.headers.name").toString(),
        value: "name",
        sortable: false
      },
      {
        text: this.$t("share.headers.actions").toString(),
        value: "actions",
        sortable: false,
        align: "end",
      },
    ];
  }

  private closeDialog(): void {
    this.$emit("closeDialog", {})
  }

  private async addParty(): Promise<void> {
    if(!this.documentItem) return;
    try{
      this.isLoading = true;
      if(this.selectedContactType === "USER_IN_CURRENT_ORGANISATION"){
        for (const userId of this.selectedParties) {
          await documentServiceV2.addPartyToDocument(this.documentItem.id, "USER", userId, null);
        }
      } else if(this.selectedContactType === "USER"){
        for (const userId of this.selectedParties) {
          await documentServiceV2.addPartyToDocument(this.documentItem.id, "USER", userId, null);
        }
      } else if(this.selectedContactType === "ORGANISATION"){
        for (const orgId of this.selectedParties) {
          await documentServiceV2.addPartyToDocument(this.documentItem.id, "ORGANISATION", orgId, null);
        }
      } else if(this.selectedContactType === "ALL_USERS"){
        await documentServiceV2.addPartyToDocument(this.documentItem.id, "ALL", null, null);
      } else if(this.selectedContactType === "BCCA"){
        await documentServiceV2.addPartyToDocument(this.documentItem.id, "ORGANISATION", store.state.appConfig.bccaId, null);
      } else if(this.selectedContactType === "USERS_WITH_ROLE"){
        for (const roleId of this.selectedRoles) {
          await documentServiceV2.addPartyToDocument(this.documentItem.id, "USERS_WITH_ROLE", null, roleId);
        }
      } else if(this.selectedContactType === "ORGANISATIONS_WITH_ROLE"){
        for (const roleId of this.selectedRoles) {
          await documentServiceV2.addPartyToDocument(this.documentItem.id, "ORGANISATIONS_WITH_ROLE", null, roleId);
        }
      }

      this.fetchDocumentShares();
      this.selectedContactType = null;
    }catch (e) {
      console.error(e);
    }finally {
      this.isLoading = false;
    }
  }

  private async removeParty(partyId): Promise<void> {
    if(!this.documentItem) return;
    await documentServiceV2.removePartyFromDocument(this.documentItem.id, partyId);
    const removeDesiredPartyIndex = this.desiredParties.findIndex(
        (party) => party.id === partyId
    );
    this.desiredParties.splice(removeDesiredPartyIndex, 1);
  }

  private async updateEligibleParties(): Promise<void> {
    this.loadingEligibleParties = true;
    this.eligibleParties = [];
    this.selectedParties = [];

    if(!this.selectedContactType
        || this.selectedContactType === "ALL_USERS"
        || this.selectedContactType === "USERS_WITH_ROLE"
        || this.selectedContactType === "ORGANISATIONS_WITH_ROLE"
        || this.selectedContactType === "BCCA"){
      this.loadingEligibleParties = false;
      return;
    }

    if(this.selectedRoles.length <= 0){
      this.loadingEligibleParties = false;
      return;
    }

    const allParties = {};
    for (const roleId of this.selectedRoles) {
      let partiesResult: any[] = [];

      if(this.selectedContactType === "USER_IN_CURRENT_ORGANISATION"){
        partiesResult = await this.getUserDataForParties(roleId, false);
      } else if(this.selectedContactType === "USER"){
        partiesResult = await this.getUserDataForParties(roleId, true);
      } else if(this.selectedContactType === "ORGANISATION"){
        partiesResult = await this.getOrganisationDataForParties(roleId);
      }
      partiesResult.forEach((party) => {
        allParties[party.id] = party;
      });
    }
    this.eligibleParties = Object.values(allParties);

    this.loadingEligibleParties = false;
  }

  private reset(): void {
    this.selectedContactType = null;

    this.selectedRoles = [];
    this.eligibleParties = [];
    this.selectedParties = [];
    this.selectedParties = [];

    this.desiredParties = [];
  }

  private async getUserDataForParties(role: string, allOrganisations = false): Promise<any[]> {
    const eligibleUsers = await roleService.getEligibleUsersForRole(role, allOrganisations, this.$store.state.user.organizationId)
    return eligibleUsers
      .filter((user) => !this.desiredParties.find((party) => party.id === user.id))
      .map((user) => {
        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          contactType: "USER",
        };
      });
  }

  private async getOrganisationDataForParties(role: string): Promise<any[]> {
    return (await roleService.getEligableOrganisations(role))
      .filter(
        (organisation) =>
          !this.desiredParties.find((party) => party.id === organisation.id)
      )
      .map((organisation) => {
        return {
          id: organisation.id,
          name: organisation.name,
          contactType: "ORGANISATION",
        };
      });
  }

  private async fetchDocumentShares() {
    if(!this.documentItem) return;
    try{
      this.isLoading = true;
      const documentInvolvedParties = await documentServiceV2.getInvolvedParties(this.documentItem.id);

      const involvedUsers = documentInvolvedParties.filter((party) => party.contactType === "USER" );
      const usersMap = {};
      if (involvedUsers && involvedUsers.length > 0) {
        const userIds = involvedUsers.map((user) => user.contactId);
        const usersResult = await userServiceV2.getUsersByIds(userIds);
        usersResult.forEach((result) => {
          const mappedResult = {
            id: result.id,
            name: `${result.firstName} ${result.lastName}`,
            contactType: "USER",
          };
          usersMap[result.id] = mappedResult;
        });
      }

      const involvedOrganisations = documentInvolvedParties.filter((party) => party.contactType === "ORGANISATION");
      const orgMap = {};
      if (involvedOrganisations && involvedOrganisations.length > 0) {
        const organisationIds = involvedOrganisations.map(
            (organisation) => organisation.contactId
        );
        const organisationsResult =
            await organisationService.getOrganisationsByIds(organisationIds);
        organisationsResult.forEach((result) => {
          const mappedResult = {
            id: result.id,
            name: result.name,
            contactType: "ORGANISATION",
          };
          orgMap[result.id] = mappedResult;
        });
      }

      this.isOwner = this.$store.state.user.contactId && (this.$store.state.user.contactId === this.documentItem?.uploadedBy?.contactId);

      this.desiredParties = [];
      documentInvolvedParties.map(party => {
        if(party.contactType === "USER"){
          return {
            id: party.id,
            contactId: party.contactId,
            name: usersMap[party.contactId]?.name || 'Unknown',
            contactType: party.contactId === this.documentItem?.uploadedBy?.contactId ? "OWNER" : "USER",
            contactTypeTranslation: party.contactId === this.documentItem?.uploadedBy?.contactId ? this.$t(this.contactTypeValues["OWNER"].sharedLabel).toString() : this.$t(this.contactTypeValues["USER"].sharedLabel).toString(),
            priority: party.contactId === this.documentItem?.uploadedBy?.contactId ? 0 : 6,
          }
        }
        if(party.contactType === "ORGANISATION"){
          return {
            id: party.id,
            contactId: party.contactId,
            name: orgMap[party.contactId]?.name || 'Unknown',
            contactType: "ORGANISATION",
            contactTypeTranslation: this.$t(this.contactTypeValues["ORGANISATION"].sharedLabel).toString(),
            priority: 5
          }
        }
        if(party.contactType === "ORGANISATIONS_WITH_ROLE"){
          return {
            id: party.id,
            contactId: party.contactId,
            name: this.$t(this.roleTranslationList[party.roleId] || "unknown").toString(),
            contactType: "ORGANISATIONS_WITH_ROLE",
            contactTypeTranslation: this.$t(this.contactTypeValues["ORGANISATIONS_WITH_ROLE"].sharedLabel).toString(),
            priority: 3
          }
        }
        if(party.contactType === "USERS_WITH_ROLE"){
          return {
            id: party.id,
            contactId: party.contactId,
            name: this.$t(this.roleTranslationList[party.roleId] || "unknown").toString(),
            contactType: "USERS_WITH_ROLE",
            contactTypeTranslation: this.$t(this.contactTypeValues["USERS_WITH_ROLE"].sharedLabel).toString(),
            priority: 4
          }
        }
        if(party.contactType === "ALL"){
          return {
            id: party.id,
            contactId: party.contactId,
            name: this.$t("sharing.contacttype.allusersname").toString(),
            contactType: "ALL",
            contactTypeTranslation: this.contactTypeValues["ALL_USERS"].sharedLabel,
            priority: 2
          }
        }
        return {
          id: party.id,
          contactId: party.contactId,
          name: party.contactId,
          contactType: "ORGANISATION",
          contactTypeTranslation: 'Other',
          priority: 6
        }
      }).forEach(value => this.desiredParties.push(value));
      if(this.sharingScopes && this.sharingScopes.fullAccess && this.sharingScopes.fullAccess.length > 0){
        this.sharingScopes.fullAccess.forEach(value => this.desiredParties.push({
          id: "",
          contactId: "",
          name: this.$t(value).toString(),
          contactType: "OWNER",
          contactTypeTranslation: this.$t("sharing.contacttypeshared.scope").toString(),
          priority: 1
        }));
      }

      this.desiredParties = this.desiredParties.sort((a, b) => {
        if(!a && !b) return 0;
        if(a && !b) return -1;
        if(!a && b) return 1;
        if(a.priority === b.priority) return (a.name || "").localeCompare(b.name);
        return a.priority - b.priority;
      });
    }catch (e) {
      console.error(e);
    }finally {
      this.isLoading = false;
    }
  }
}
