import ReferenceData from "@/contracts/generic/ReferenceData";
import axios from "@/plugins/axios";
import ActivityModel from "@/app/models/activity/activity.model";
import SortConfig from "@/contracts/generic/SortConfig";
import PageConfig from "@/contracts/generic/PageConfig";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { activityDefinitionMock } from "@/app/mock/activity.mock";
import { Overview } from "@/app/models/overview/overview.model";
import { activityOverviewMock } from "@/app/mock/activity-overview.mock";
import store from "@/store";

class ActivityService {

  private definitionCache: {[key: string]: any} = {}
  public getEligibleActivitiesForUser(
    userId: string
  ): Promise<ActivityModel[]> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/users/${userId}/eligible-activities?sort=ae_name,asc&page=0&pageSize=100&q=`;
    return axios.get<Overview>(url).then((response) => {
      return response?.data?._embedded?.results || [];
    });
    // return Promise.resolve(activityOverviewMock[userId]);
  }

  async getActivityDefinition(activityId: string): Promise<ActivityDefinitionModel> {
    if(this.definitionCache[activityId]) return Promise.resolve(this.definitionCache[activityId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/activities/definition/${activityId}`;
    return axios
      .get<ActivityDefinitionModel>(url, {
        headers: { "X-ActiveActivity": activityId },
      })
      .then((response) => {
        if(response.status === 200){
          this.definitionCache[activityId] = response?.data;
          return response?.data;
        }else{
          return Promise.reject('Could not load definition');
        }
      });
    // return Promise.resolve(activityDefinitionMock[activityId]);
  }
}

export const activityServicev2 = new ActivityService();
